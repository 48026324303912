import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

// We are receiving lots of error reports caused when trying to parse a minified file loaded from a
// CDN. Most of these errors are caused on very specific versions of the browsers for a few users.
// Probably it's because those are very old versions or because they contains a bug.
const minifyingError = (error) =>
  error && error.message && error.message.match(/Unexpected token \'\)\'/)

Sentry.init({
  dsn: window.SENTRY_DSN,
  release: window.SENTRY_RELEASE,
  environment: 'production',
  tracesSampleRate: 1.0,
  integrations: [new BrowserTracing()],
  beforeSend(event, hint) {
    if (minifyingError(hint.originalException)) return null

    return event
  }
})

window.Sentry = Sentry
